<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link tag="a" to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo.svg" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/eventfy.png" alt height="44" />
            </span>
          </router-link>

          <router-link tag="a" to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-light.svg" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/eventfy.png" alt height="44" />
            </span>
          </router-link>
        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item"
          @click="toggleMenu"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

      </div>

      <div class="d-flex">

        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button
            type="button"
            class="btn header-item noti-icon"
            @click="initFullScreen"
          >
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>

        <b-dropdown right variant="black" toggle-class="header-item">
          <template v-slot:button-content>
            <span class="d-none d-xl-inline-block ml-1">{{ user.name }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
          <a
            href="javascript:void(0)"
            @click="logout()"
            class="dropdown-item text-danger"
          >
            <i
              class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"
            ></i>
            {{ $t("navbar.dropdown.userData.list.logout") }}
          </a>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>
<script>
import i18n from '../i18n'
import { mapState, mapActions } from 'vuex'

/**
 * Nav-bar Component
 */
export default {
  data () {
    return {
      languages: [
        {
          flag: require('@/assets/images/flags/br.jpg'),
          language: 'br',
          title: 'Português'
        }
        // {
        //   flag: require('@/assets/images/flags/us.jpg'),
        //   language: 'en',
        //   title: 'English'
        // },
        // {
        //   flag: require('@/assets/images/flags/spain.jpg'),
        //   language: 'es',
        //   title: 'Spanish'
        // }
      ],
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null
    }
  },
  components: { },
  mounted () {
    this.value = this.languages.find((x) => x.language === i18n.locale)
    this.text = this.value.title
    this.flag = this.value.flag
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('auth', {
      logoutUser: 'ActionSignOut'
    }),
    toggleMenu () {
      this.$parent.toggleMenu()
    },
    initFullScreen () {
      document.body.classList.toggle('fullscreen-enable')
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen()
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen()
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          )
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        }
      }
    },
    setLanguage (locale, country, flag) {
      this.lan = locale
      this.text = country
      this.flag = flag
      i18n.locale = locale
    },
    logout () {
      this.logoutUser().then(res => {
        this.$router.push({
          name: 'login'
        })
      })
      console.log('chamou logout')
    }
  }
}
</script>
